'use client';

import { useCallback, useContext, useState } from 'react';
import { useTranslation } from '../../hooks/use-translation';
import AppPageContext from '../../context/app-page-context';
import { ProgressRing } from './progress-ring';
import SpriteIcon from '../shared/sprite-icon';
import { ReviewsModal } from './reviews-modal';
import { useReviews } from '../../hooks/use-reviews';
import { ReviewSlider } from './review-slider';
const kNumOfReviewsPerPage = 50;

// -----------------------------------------------------------------------------
export const Reviews = (): JSX.Element => {
  const { t } = useTranslation();
  const { app } = useContext(AppPageContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allReviewsLoaded, setAllReviewsLoaded] =
    useState<boolean>(kNumOfReviewsPerPage > app.initialReviews.totalRateCount);
  const [page, setPage] = useState<number>(1);

  const {
    reviews,
    isLoading,
    loadMoreReviews,
    slideReviews,
  } = useReviews(app.initialReviews?.comments ?? null);

  const handleLoadMore = useCallback(async (): Promise<void> => {

    const newPage = page + 1;
    setPage(newPage);
    await loadMoreReviews(app, newPage);
    if (newPage * kNumOfReviewsPerPage >= app.initialReviews.totalRateCount) {
      setAllReviewsLoaded(true);
    }

  }, [app.id, page, loadMoreReviews, allReviewsLoaded]);

  const handleToggleOpen = (): void => {
    setIsOpen(cur => !cur);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  if (!app.initialReviews || app.initialReviews.totalRateCount === 0) {
    return (<></>);
  }

  return (
    <section className='reviews-section' id='reviews'>
      <h2>{t('reviews')}</h2>

      <div className='ratings-dashboard'>
        <div className='dashboard-view'>

          <div className='rating-box'>
            <h3>
              <span>{app.initialReviews.averageRate}</span>
              <span className='best-rating'>/ 5.0</span>
            </h3>
            <h4>
              <span className='reviews-amount-data'>
                {app.initialReviews.totalRateCount}
              </span> {`${t('ratings')}`}
            </h4>
          </div>

          <ul className='rating-stats'>
            {
              app.initialReviews.rateDistribution.map(segment => {
                return (
                  <ProgressRing key={segment.stars} segment={segment} />
                );
              })
            }
          </ul>

          <button
            className='clean-btn'
            onClick={handleToggleOpen}
          >
            {t('viewAll')}
            <SpriteIcon icon='arrowRight' />
          </button>

        </div>
      </div>

      <ReviewSlider reviews={slideReviews} />

      <ReviewsModal
        isOpen={isOpen}
        reviews={reviews}
        isLoading={isLoading}
        onClickClose={handleClose}
        loadMoreReviews={handleLoadMore}
        allReviewsLoaded={allReviewsLoaded}
      />

    </section>
  );
};
